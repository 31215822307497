<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ user.id }} {{ user.firstname }} {{ user.lastname }}</h1>
    </div>
    <div class="card-content">
      <div class="columns is-6 view-columns">
        <div class="column">
          <label>Benutzer-Id</label>
          #{{ user.id }}
        </div>
        <div class="column">
          <label>Firma</label>
          {{ user.company }}
        </div>
        <div class="column">
          <label>Vorname</label>
          {{ user.firstname }}
        </div>
        <div class="column">
          <label>Nachname</label>
          {{ user.lastname }}
        </div>
      </div>

      <div class="columns view-columns is-6">
        <div class="column">
          <label>Position</label>
          {{ user.position }}
        </div>
        <div class="column">
          <label>E-Mail</label>
          <a :href="'mailto:' + user.email">{{ user.email }}</a>
        </div>
        <div class="column">
          <label>Telefon</label>
          <a :href="'tel:' + user.telephone">{{ user.telephone }}</a>
        </div>
        <div class="column">
          <label>Aktiv</label>
          {{ user.active == 1 ? 'ja' : 'nein' }}
        </div>
      </div>
      <div class="columns view-columns is-6">
        <div class="column">
          <label>Berechtigungen</label>
          <p v-html="translatePerm(user.permissions)"></p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">Schließen</button> &nbsp;

        <b-button class="button" type="is-info" @click="editUser(user.id)" icon-left="square-edit-outline"
          >Bearbeiten</b-button
        >

        &nbsp;

        <b-button
          class="button"
          type="is-danger"
          icon-left="delete_forever"
          @click="deleteUser(user.id, user.firstname + ' ' + user.lastname)"
          >Löschen</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userView',
  props: ['user', 'routes'],

  methods: {
    translatePerm: function(userPermissions) {

      if(typeof userPermissions == 'string')
      {
        userPermissions = JSON.parse(userPermissions)
      }
      var permList = []
      var color = 'icon-grey'
      var path = ''
      var routes = this.$router.options.routes
      for (var i = 0; i < routes.length; i++) {
        if (routes[i].meta.menu != 'main') {
          continue
        }
        color = 'icon-grey'
        path = routes[i].path.substr(1).replace('*', '')
        if (typeof userPermissions[path] != 'undefined' && userPermissions[path] == 1) {
          color = 'icon-green'
        }

        permList.push(
          '<i class="icm icm-' +
            routes[i].meta.icon +
            ' ' +
            color +
            '" title="' +
            routes[i].meta.name +
            '"></i> &nbsp;' +
            routes[i].meta.name
        )
      }

      return permList.join(' <br> ')
    },
    deleteUser: function(id, name) {
      this.$parent.close()
      this.$parent.$parent.deleteUser(id, name)
    },
    editUser: function(id) {
      this.$parent.close()
      this.$parent.$parent.editUser(id)
    }
  }
}
</script>
