<template>
  <div class="card">
    <form method="post" @submit.prevent="saveUser">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns is-6 view-columns">
          <div class="column">
            <label>Benutzer-Id</label>
            #{{ user.id }}
          </div>
          <div class="column">
            <label>Firma</label>
            <b-input v-model.trim="$v.user.company.$model" :class="{ is_danger: $v.user.company.$error }"> </b-input>
            <div class="is-error" v-if="submitted && $v.user.company.$error">
              Bitte geben Sie eine Firma an.
            </div>
          </div>
          <div class="column">
            <label>Vorname</label>
            <b-input v-model.trim="$v.user.firstname.$model" :class="{ is_danger: $v.user.firstname.$error }">
            </b-input>
            <div class="is-error" v-if="submitted && $v.user.firstname.$error">
              Bitte geben Sie einen Vornamen ein.
            </div>
          </div>
          <div class="column">
            <label>Nachname</label>
            <b-input v-model.trim="$v.user.lastname.$model" :class="{ is_danger: $v.user.lastname.$error }"> </b-input>
            <div class="is-error" v-if="submitted && $v.user.lastname.$error">
              Bitte geben Sie einen Nachnamen ein.
            </div>
          </div>
        </div>

        <div class="columns view-columns is-6">
          <div class="column">
            <label>Position (optional)</label>
            <b-input v-model.trim="$v.user.position.$model" :class="{ is_danger: $v.user.position.$error }"> </b-input>
          </div>
          <div class="column">
            <label>E-Mail</label>
            <b-input v-model.trim="$v.user.email.$model" :class="{ is_danger: $v.user.email.$error }"> </b-input>
            <div class="is-error" v-if="submitted && $v.user.email.$error">
              Bitte geben Sie eine gültige E-Mail-Adresse ein.
            </div>
          </div>
          <div class="column">
            <label>Telefon</label>
            <b-input v-model.trim="$v.user.telephone.$model" :class="{ is_danger: $v.user.telephone.$error }">
            </b-input>
            <div class="is-error" v-if="submitted && $v.user.telephone.$error">
              Bitte geben Sie eine Telefonnummer inkl. Ländervorwahl ein (z.B. +491729876543).
            </div>
          </div>
          <div class="column">
            <label>Aktiv</label>
            <b-switch v-model="user.active" true-value="1" false-value="0" size="is-small"> </b-switch>
          </div>
        </div>
        <div class="columns view-columns is-6">
          <div class="column">
            <label>Berechtigungen</label>
            <div v-for="p in permissionList" :key="p.id">
              <b-switch
                @input="setPermission(p.module, $event)"
                true-value="1"
                false-value="0"
                size="is-small"
                :value="p.value"
              >
                <b-icon :icon="p.icon"></b-icon> {{ p.name }}
              </b-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <button class="button" type="button" @click="$parent.close()">Schließen</button> &nbsp;

          <b-button class="button" type="is-info" native-type="submit" icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
const valTelephone = (value) => /^\+[0-9]{6,20}$/.test(value)
export default {
  name: 'userForm',
  props: ['puser', 'routes'],

  data() {
    return {
      user: this.puser,
      error: null,
      isLoading: false,
      submitted: false
    }
  },

  computed: {
    typeOfAction: function() {
      if (typeof this.user.id == 'undefined' || this.user.id == null) {
        return 'Neuen Benutzer anlegen'
      }
      return 'Benutzer bearbeiten: #' + this.user.id + ' ' + this.user.firstname + ' ' + this.user.lastname
    },
    permissionList: function() {

      if(typeof  this.user.permissions == 'string')
      {
        this.user.permissions = JSON.parse(this.user.permissions)
      }
      var permList = {}
      for (var i = 0; i < this.routes.length; i++) {
        if (this.routes[i].meta.menu != 'main') {
          continue
        }

        var path = this.routes[i].path.substr(1).replace('*', '')
        permList[path] = {
          value: 0,
          icon: this.routes[i].meta.icon,
          name: this.routes[i].meta.name,
          module: path
        }
        if (this.user.permissions != null && typeof this.user.permissions[path] != 'undefined' && this.user.permissions[path] == 1) {
          permList[path].value = 1
        }
      }

      return permList
    }
  },
  validations: {
    user: {
      company: {
        required,
        minLength: minLength(3)
      },
      firstname: {
        required,
        minLength: minLength(2)
      },
      lastname: {
        required,
        minLength: minLength(2)
      },
      position: {},
      email: {
        required,
        email
      },
      telephone: {
        required,
        valTelephone
      }
    }
  },
  methods: {

    setPermission: function(module, value) {
      //console.log(module + ' : ' + value)
      this.user.permissions[module] = value
    },
    saveUser: function() {
      //console.log('user-daten');
      //console.log(this.user)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var s = this
      if (s.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        //console.log('speichern1')
        if (s.$parent.$parent.saveUser(s.user)) {
          s.$parent.close()
        }
        //console.log('speichern2')
      }
    }
  }
}
</script>
